<template>
    <Navbar />
</template>

<script>
import Navbar from "../components/header/TheNav"

export default {
    name: "TheHeader",
    components: {
        Navbar
    }
}
</script>
