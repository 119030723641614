<template>
    <Footer />
</template>

<script>
import Footer from "../components/footer/Footer"

export default {
    name: "TheFooter",
    components: {
        Footer
    }
}
</script>
